import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * get emailid for logout
   * @returns user Name or 'Logout'
   */
  get emailid(): string | null {
    return sessionStorage.getItem("UserName")
      ? sessionStorage.getItem("UserName")
      : "Log out";
  }

  /**
   * get User Credentials for Modules
   * @returns User
   */
  get ModuleAcess(): string | null {
    return sessionStorage.getItem("ModuleAcess")
      ? sessionStorage.getItem("ModuleAcess")
      : null;
  }

  /**
   * getDomain Name or Company Name
   * @returns Domain Name or Company Name
   */
  get AccountName(): string | null {
    return sessionStorage.getItem("AccountName")
      ? sessionStorage.getItem("AccountName")
      : null;
  }

  /**
   * get AccountId
   * @returns AccountId
   */
  get AccountId(): string | null {
    return sessionStorage.getItem("AccountId")
      ? sessionStorage.getItem("AccountId")
      : null;
  }

  /**
   * get DomainName
   * @returns DomainName
   */
  get DomainName(): string | null {
    return sessionStorage.getItem("DomainName")
      ? sessionStorage.getItem("DomainName")
      : null;
  }
  /**
   * get AccessToken
   * @returns AccessToken
   */
  get AccessToken(): string | null {
    return sessionStorage.getItem("AccessToken")
      ? sessionStorage.getItem("AccessToken")
      : null;
  }

  /**
   * get DocumentExchangeDate for Filtering data
   * @returns DocumentExchangeDate
   */
  get DocExchangeDate(): string | null {
    return sessionStorage.getItem("DocExchangeDate")
      ? sessionStorage.getItem("DocExchangeDate")
      : null;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * @description Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];

    if (user != null) {
      sessionStorage.setItem("UserName", user[0].email);
      sessionStorage.setItem("ModuleAcess", JSON.stringify(user[0].rimModel));
      this.user.name = user[0].email;
      //console.log(JSON.stringify(user[0].rimModel));
      sessionStorage.setItem("AccountName", user[0].accountname);
      sessionStorage.setItem("AccountId", user[0].accountid);
      // sessionStorage.setItem("DomainName", "EIC");
      // sessionStorage.setItem("DomainName", "ESS");
      sessionStorage.setItem(
        "DomainName",
        process.env.VUE_APP_DomainName.toUpperCase()
      );
      //This is used for isuserAuthenticated
      JwtService.saveToken(this.user.token);
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    credentials.domain = process.env.VUE_APP_DomainName;

    //alert(JSON.stringify(credentials));

    ApiService.setToken(credentials.email, credentials.password);

    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/users/getusers", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ error }) => {
          this.context.commit(Mutations.SET_ERROR, [
            "EmailId or Password incorrect",
          ]);
          reject();
        });
    });

    /*
    return new Promise<void>((resolve, reject) => {
      ApiService.get("api/users/validate")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ error }) => {
          this.context.commit(Mutations.SET_ERROR, [
            "EmailId or Password incorrect",
          ]);
          reject();
        });
    });
    */
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (ApiService.getToken()) {
      ApiService.setHeader();
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    //ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
